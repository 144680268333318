import 'antd/lib/style/index.less' // antd core styles
import './global.scss' // app & third-party component styles

import React from 'react'
import ReactDOM from 'react-dom'
import { createBrowserHistory } from 'history'
import { createStore, applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux'
// import { logger } from 'redux-logger'
import createSagaMiddleware from 'redux-saga'
import { routerMiddleware } from 'connected-react-router'
import {persistStore, persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { PersistGate } from 'redux-persist/integration/react';

import reducers from './redux/reducers'
import sagas from './redux/sagas'
import Localization from './localization'
import Router from './router'
import * as serviceWorker from './serviceWorker'

// mocking api
import 'services/axios/fakeApi'

// middlewared
const history = createBrowserHistory()
const sagaMiddleware = createSagaMiddleware()
const routeMiddleware = routerMiddleware(history)
const middlewares = [sagaMiddleware, routeMiddleware]

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// if (process.env.NODE_ENV === 'development') {
  // middlewares.push(logger)
// }
let reduxStore

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['sortParameter'] // which reducer want to store
};

const pReducer = persistReducer(persistConfig, reducers(history));

if (process.env.NODE_ENV === 'development') {
  reduxStore = createStore(pReducer, composeEnhancers(applyMiddleware(...middlewares)))
} else {
  reduxStore = createStore(pReducer, compose(applyMiddleware(...middlewares)))
}
const store = reduxStore
const persistor = persistStore(store)
// const store = createStore(reducers(history), composeEnhancers(applyMiddleware(...middlewares)))
sagaMiddleware.run(sagas)

ReactDOM.render(
  <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
      <Localization>
        <Router history={history} />
      </Localization>
      </PersistGate>
  </Provider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
export { store, history }
