import { PubDashLocalStorage, PubDashStorageKeys } from '../contracts'


const baseAppUrl = process.env.REACT_APP_API_HOST
const setHeaders = (urlEncodedForm, useToken) => {
  const authToken = PubDashLocalStorage.get(PubDashStorageKeys.Token, true)
  const additionalHeaders = {}
  if (authToken && useToken) {
    additionalHeaders.Authorization = `Bearer ${authToken}`
  }
  if (urlEncodedForm) {
    additionalHeaders['Content-Type'] = 'application/x-www-form-urlencoded; charset=utf-8'
  } else {
    additionalHeaders['Content-Type'] = 'application/json'
    additionalHeaders.Accept = 'application/json'
  }
  additionalHeaders['Cache-Control'] = 'no-cache'
  return additionalHeaders
}
export const getRequest = (url, data, useToken, urlEncoded) => {
  const options = {
    method: 'GET',
    baseURL: baseAppUrl,
    url,
  }
  if (data) {
    options.data = data
  }
  options.headers = setHeaders(urlEncoded, useToken)
  return options
}
export const postRequest = (url, data, useToken, urlEncoded) => {
  const options = {
    method: 'POST',
    baseURL: baseAppUrl,
    url,
  }
  if (data) {
    options.data = data
  }
  options.headers = setHeaders(urlEncoded, useToken)
  return options
}

export const putRequest = (url, data, useToken, urlEncoded) => {
  const options = {
    method: 'PUT',
    baseURL: baseAppUrl,
    url,
  }
  if (data) {
    options.data = data
  }
  options.headers = setHeaders(urlEncoded, useToken)
  return options
}
export const deleteRequest = (url, data, useToken, urlEncoded) => {
  const options = {
    method: 'DELETE',
    baseURL: baseAppUrl,
    url,
  }
  if (data) {
    options.data = data
  }
  options.headers = setHeaders(urlEncoded, useToken)
  return options
}
export const patchRequest = (url, data, useToken, urlEncoded) => {
  const options = {
    method: 'PATCH',
    baseURL: baseAppUrl,
    url,
  }
  if (data) {
    options.data = data
  }
  options.headers = setHeaders(urlEncoded, useToken)
  return options
}
