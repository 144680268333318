import apiClient from 'services/axios';
import store from 'store';

import { PubDashLocalStorage, PubDashStorageKeys } from '../../contracts';

const baseAppUrl = process.env.REACT_APP_API_HOST;

export async function login(email, password) {
    return apiClient
        .post(`${baseAppUrl}/api/v2/auth/login/`, {
            email,
            password,
        })
        .then(loginResponse => {
            if (loginResponse) {
                const { access_token: accessToken } = loginResponse.data;
                if (accessToken) {
                    store.set('accessToken', accessToken);
                    PubDashLocalStorage.save(PubDashStorageKeys.Token, accessToken);
                }
                return { success: true, data: loginResponse.data };
            }
            return { success: false, data: null };
        })
        .catch(err => console.log(err));
}

export async function register(email, password, name) {
    return apiClient
        .post(`${baseAppUrl}/auth/register`, {
            email,
            password,
            name,
        })
        .then(response => {
            if (response) {
                const { accessToken } = response.data;
                if (accessToken) {
                    store.set('accessToken', accessToken);
                }
                return response.data;
            }
            return false;
        })
        .catch(err => console.log(err));
}

export async function currentAccount() {
    const authToken = PubDashLocalStorage.get(PubDashStorageKeys.Token, true);
    if (authToken) {
        return apiClient
            .get(`${baseAppUrl}/api/v2/users/me`)
            .then(response => {
                if (response) {
                    const { accessToken } = response.data;
                    if (accessToken) {
                        store.set('accessToken', accessToken);
                    }
                    return response.data;
                }
                return false;
            })
            .catch(err => console.log(err));
    }
}

export async function logout() {
    return apiClient
        .post(`${baseAppUrl}/auth/logout/`)
        .then(() => {
            store.clearAll();
            return true;
        })
        .catch(err => console.log(err));
}
