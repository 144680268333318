import { all, put, call, takeEvery } from 'redux-saga/effects';
import actions from './actions';

import getMenuData from 'services/menu';
import { getWebsiteStatus } from 'services/top-bar';
import { getWebsitesData } from 'services/top-bar';
import { getUniqueGAMAccounts } from '../../pages/utils';

export function* GET_DATA() {
    const menuData = yield call(getMenuData);
    yield put({
        type: 'menu/SET_STATE',
        payload: {
            menuData,
        },
    });
}

export function* LOAD_WEBSITE_STATUS() {
    yield put({
        type: 'menu/SET_STATE',
        payload: {
            loading: true,
        },
    });
    try {
        const { data } = yield call(getWebsiteStatus, null);
        const { results } = data;

        if (results) {
            yield put({
                type: 'menu/SET_STATE',
                payload: {
                    website_status: results,
                    websites: results,
                    authorized: true,
                    gamAccounts: getUniqueGAMAccounts(results),
                },
            });
        }
        if (!results) {
            yield put({
                type: 'menu/SET_STATE',
                payload: {
                    loading: false,
                },
            });
        }
    } catch (error) {
        console.error(error);
    }
}

export function* LOAD_WEBSITES_DATA() {
    yield put({
        type: 'menu/SET_STATE',
        payload: {
            loading: true,
        },
    });
    try {
        const { data } = yield call(getWebsitesData);
        const { results } = data;

        if (results) {
            yield put({
                type: 'menu/SET_STATE',
                payload: {
                    websites: results,
                    authorized: true,
                },
            });
        }
        if (!results) {
            yield put({
                type: 'menu/SET_STATE',
                payload: {
                    loading: false,
                },
            });
        }
    } catch (error) {
        console.error(error);
    }
}

export default function* rootSaga() {
    yield all([takeEvery(actions.LOAD_WEBSITE_STATUS, LOAD_WEBSITE_STATUS), GET_DATA()]);
}
