import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { UserOutlined } from '@ant-design/icons'
import { Menu, Dropdown, Avatar } from 'antd'
import styles from './style.module.scss'
import {clearSelection} from '../../../../../redux/sortParameter/action'

const mapStateToProps = ({ user }) => ({ user })

const ProfileMenu = ({ dispatch, user }) => {
  const logout = e => {
    e.preventDefault()
    dispatch({
      type: 'user/LOGOUT',
    })
    dispatch(clearSelection())
  }

  const menu = (
    <Menu selectable={false}>
      <Menu.Item style={{ pointerEvents: 'none' }}>
        <strong>
          <FormattedMessage id="topBar.profileMenu.hello" />,{' '}
          {user.first_name + ' ' + user.last_name || 'Anonymous'}
        </strong>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item style={{ pointerEvents: 'none' }}>
        <div>
          <strong>
            <FormattedMessage id="topBar.profileMenu.email" />:{' '}
          </strong>
          {user.email || '—'}
        </div>
      </Menu.Item>
      <Menu.Divider />
      {/*<Menu.Item style={{ pointerEvents: 'none' }}>
          <i className="fe fe-user mr-2" />
          <FormattedMessage id="topBar.profileMenu.editProfile" />
      </Menu.Item>
      <Menu.Divider />*/}
      <Menu.Item>
        <a href="#" onClick={logout}>
          <i className="fe fe-log-out mr-2" />
          <FormattedMessage id="topBar.profileMenu.logout" />
        </a>
      </Menu.Item>
    </Menu>
  )
  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <div className={styles.dropdown}>
        <Avatar
          className={styles.avatar}
          shape="circle"
          size="large"
          icon={<UserOutlined />}
          src={user.avatar}
        />
      </div>
    </Dropdown>
  )
}

export default connect(mapStateToProps)(ProfileMenu)
