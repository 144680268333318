import { notification } from 'antd';
import { all, takeEvery, put, call, select } from 'redux-saga/effects';

import { history } from 'index';
import * as jwt from 'services/jwt';

import actions from './actions';

const mapAuthProviders = {
    jwt: {
        login: jwt.login,
        register: jwt.register,
        currentAccount: jwt.currentAccount,
        logout: jwt.logout,
    },
};

export function* REGISTER({ payload }) {
    const { email, password, name } = payload;
    yield put({
        type: actions.SET_STATE,
        payload: {
            loading: true,
        },
    });

    const { authProvider } = yield select(state => state.settings);
    const success = yield call(mapAuthProviders[authProvider].register, email, password, name);
    if (success) {
        yield put({
            type: actions.LOAD_CURRENT_ACCOUNT,
        });
        yield history.push('/');
        notification.success({
            message: 'Succesful Registered',
            description: 'You have successfully registered!',
        });
    }
    if (!success) {
        yield put({
            type: actions.SET_STATE,
            payload: {
                loading: false,
            },
        });
    }
}

export function* LOAD_CURRENT_ACCOUNT() {
    yield put({
        type: actions.SET_STATE,
        payload: {
            loading: true,
        },
    });
    yield history.push('/');
    const { authProvider } = yield select(state => state.settings);
    if (authProvider) {
        const response = yield call(mapAuthProviders[authProvider].currentAccount);
        if (response) {
            const { id, email, first_name, last_name, avatar, role, publisher } = response;
            yield put({
                type: actions.SET_STATE,
                payload: {
                    id,
                    first_name,
                    last_name,
                    email,
                    avatar,
                    role,
                    publisher,
                    authorized: true,
                },
            });
        }
    }

    yield put({
        type: actions.SET_STATE,
        payload: {
            loading: false,
        },
    });
}

export function* LOGIN({ payload }) {
    try {
        const { email, password } = payload;

        yield put({
            type: actions.SET_STATE,
            payload: {
                loading: true,
            },
        });

        const { authProvider: authProviderName } = yield select(state => state.settings);

        const response = yield call(mapAuthProviders[authProviderName].login, email, password);

        if (response.success) {
            const { access_token: token } = response.data;
            const { id, email, first_name, last_name, avatar, role, publisher } = response.data.user;
            yield put({
                type: actions.SET_STATE,
                payload: {
                    id,
                    token,
                    email,
                    first_name,
                    last_name,
                    avatar,
                    role,
                    publisher,
                },
            });

            notification.success({
                message: 'Logged In',
                description: 'You have successfully logged in!',
            });
            yield LOAD_CURRENT_ACCOUNT();
            yield put(history.push('/'));
        } else {
            yield put({
                type: actions.SET_STATE,
                payload: {
                    loading: false,
                },
            });
        }
    } catch (e) {
        yield put({
            type: actions.SET_STATE,
            payload: {
                loading: false,
            },
        });
    }
}

export function* LOGOUT() {
    const { authProvider } = yield select(state => state.settings);
    yield call(mapAuthProviders[authProvider].logout);
    yield put({
        type: actions.SET_STATE,
        payload: {
            id: '',
            first_name: '',
            last_name: '',
            role: '',
            email: '',
            avatar: '',
            publisher: {},
            authorized: false,
            loading: false,
        },
    });
}

export default function* rootSaga() {
    yield all([
        takeEvery(actions.LOGIN, LOGIN),
        // takeEvery(actions.REGISTER, REGISTER),
        takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
        takeEvery(actions.LOGOUT, LOGOUT),
        LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
    ]);
}
