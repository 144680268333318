import axios from 'axios';

import { getRequest } from '../BackendService';

const getWebsitesUrl = '/api/v2/websites';

export const getWebsiteStatus = status => {
    return axios(getRequest(status ? `${getWebsitesUrl}?status=${status}` : getWebsitesUrl, null, true, false));
};
export const getWebsitesData = status => {
    return axios(getRequest(status ? `${getWebsitesUrl}` : getWebsitesUrl, null, true, false));
};
