import {SET_WEBSITES, CLEAR_SELECTION } from './action'

const INITIAL_STATE = {
  sortDate: { start_date: null, end_date: null },
  sortMonth: { start_month: null, end_month: null },
  selectedWebsites: []
}

export default function setSelectedWebsites(state=INITIAL_STATE, action){
  switch(action.type) {
    case SET_WEBSITES:
      return {...state, ...action.payload}
    case CLEAR_SELECTION:
      return INITIAL_STATE
    default:
      return state
  }
}
