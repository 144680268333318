import { AuthToken } from './login';

class SessionStorage {
    constructor() {
        this.get = (key, parseJson) => {
            let data = sessionStorage.getItem(key) || null;
            if (data && parseJson) {
                try {
                    data = JSON.parse(data);
                } catch (error) {
                    console.error(error);
                }
            }
            return data;
        };
        this.save = (key, data) => {
            sessionStorage.setItem(key, JSON.stringify(data));
        };
        this.deleteItem = key => {
            sessionStorage.removeItem(key);
        };
        this.deleteAll = () => {
            sessionStorage.clear();
        };
    }
}
export const PubDashSessionStorage = new SessionStorage();
class LocalStorage {
    constructor() {
        this.get = (key, parseJson) => {
            let data = localStorage.getItem(key) || null;
            if (data && parseJson) {
                try {
                    data = JSON.parse(data);
                } catch (error) {
                    console.error(error);
                }
            }
            return data;
        };
        this.save = (key, data) => {
            localStorage.setItem(key, JSON.stringify(data));
        };
        this.deleteItem = key => {
            localStorage.removeItem(key);
        };
        this.deleteAll = () => {
            localStorage.clear();
        };
    }
}
export const PubDashLocalStorage = new LocalStorage();
export class HttpErrorResponse {
    constructor() {
        this.code = 0;
        this.description = '';
        this.errors = new AuthToken();
        this.subtype = '';
    }
}

export class LinkHref {
    constructor() {
        this.href = '';
    }
}
export class Links {
    constructor() {
        this.next = new LinkHref();
        this.self = new LinkHref();
    }
}
export class DeletedRecord {
    constructor() {
        this.deleted = false;
    }
}
export class NotificationConfiguration {
    constructor() {
        this.api_key = '';
        this.app_id = '';
        this.token = '';
    }
}
export var PubDashEntities;
(function(PubDashEntities) {
    PubDashEntities.Dashboard = 'dashboard';
    PubDashEntities.Inventory = 'inventory';
    PubDashEntities.InventorySettings = 'inventory-settings';
    PubDashEntities.Reports = 'reports';
    PubDashEntities.Profile = 'profile';
})(PubDashEntities || (PubDashEntities = {}));
export var PubDashRoutes;
(function(PubDashRoutes) {
    PubDashRoutes.Settings = '/settings';
    PubDashRoutes.Error = '/error';
})(PubDashRoutes || (PubDashRoutes = {}));
export class PubDashEntityRequest {
    constructor(entityType, entityId, accountId) {
        this.entityType = entityType;
        this.entityId = entityId;
        this.accountId = accountId;
    }
}

export const PubDashAuthRoutes = {
    Login: '/auth/login/',
    Logout: '/auth/logout/',
};

export const PubDashStorageKeys = {
    Token: 'token',
    CurrentWebsite: 'website',
    SelectedWebsites: 'selected_websites',
    SelectedGAMAccounts: 'selected_gam_accounts',
    prebidDimension: 'day_of_week',
    websitesList: [],
    dateRange: { start_date: null, end_date: null },
};

export const PasswordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/;
export const AlphabetsOnlyRegex = /^[a-zA-Z]+$/;
export const AlphaNumericWithDashesRegex = /^[A-Za-z0-9_-]*$/;
export const CurrencyOptions = ['USD', 'AUD', 'NZD', 'EUR', 'GBP', 'INR'];
export const DefaultErrorMessage = 'Something went wrong';
export const DateFormat = 'YYYY-MM-DD';
export const MonthFormat = 'MMM YYYY';
export const DisplayDateFormat = "Do MMM 'YY";
export const DisplayMonthFormat = "MMM 'YY";
