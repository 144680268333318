import axios from 'axios';
import { notification } from 'antd';

import { PubDashLocalStorage, PubDashStorageKeys } from 'contracts';
import store from 'store';
import { history } from 'index';

const baseAppUrl = process.env.REACT_APP_API_HOST;

const apiClient = axios.create({
    baseURL: baseAppUrl,
    // timeout: 1000,
    // headers: { 'X-Custom-Header': 'foobar' }
});

apiClient.interceptors.request.use(request => {
    const accessToken = PubDashLocalStorage.get(PubDashStorageKeys.Token, true);
    if (accessToken) {
        request.headers.Authorization = `Bearer ${accessToken}`;
    }
    return request;
});

apiClient.interceptors.response.use(
    response => response,
    error => {
        // Errors handling
        const { response } = error;
        const { data, status } = response;

        if (data && data.detail) {
            notification.warning({
                message: data.detail,
            });
        }
        if (status === 401) {
            store.clearAll();
            history.push('/auth/login');
        }
    },
);

export default apiClient;
