import { differenceWith, isEqual, isEmpty, isObject } from 'lodash';

export const isArrayEqual = function(arr1, arr2) {
    return isEmpty(differenceWith(arr1, arr2, isEqual));
};

//example Obj = {start_date:new Date(), end_date: new Date()}
export const makeFilterData = function(filterObj) {
    let filterString = '';
    if (filterObj && isObject(filterObj) && !isEmpty(filterObj)) {
        Object.keys(filterObj).forEach(filterKey => {
            if (filterKey && filterObj[filterKey]) {
                filterString = `${filterString}${filterKey}=${filterObj[filterKey]}&`;
            }
        });
    }
    return filterString;
};

export const renderValue = value => (value === null || value === undefined || value === '' ? '-' : value);

export const formatNumber = num => (isNaN(num) ? 0 : num.toLocaleString(undefined, { maximumFractionDigits: 2 }));

export const getUniqueGAMAccounts = websites => {
    const accounts = {};
    websites.forEach(website => {
        if (website.gam_account && website.gam_account.slug) {
            if (accounts[website.gam_account.slug] === undefined) {
                accounts[website.gam_account.slug] = website.gam_account;
            }
        }
    });
    return Object.values(accounts);
};
