export const SET_WEBSITES = "SET_WEBSITES"
export const CLEAR_SELECTION = "CLEAR_SELECTION"

export const setSelectedWebsites = (sortOptions) => {
  return {
    type: SET_WEBSITES,
    payload: sortOptions
  }
}

export function clearSelection(){
  return {
    type:CLEAR_SELECTION
};
}
