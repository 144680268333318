import React, { useEffect } from 'react';
import UserMenu from './UserMenu';
import style from './style.module.scss';
import { connect } from 'react-redux';
import getMenuData from '../../../../redux/menu/actions';

const TopBar = ({ logo, publisher, getWebsites }) => {
    useEffect(() => {
        getWebsites();
    }, []);
    return (
        <div className={style.topbar}>
            {logo === 'pubX.ai' && (
                <div className={style.descr} style={{ zIndex: 100, fontWeight: 600 }}>
                    {publisher ? publisher.name : ''}{' '}
                </div>
            )}
            <div className="ml-auto mr-4 d-none d-sm-block">
                <UserMenu />
            </div>
        </div>
    );
};

const mapStateToProps = ({ settings, user }) => {
    return {
        logo: settings.logo,
        publisher: user.publisher,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getWebsites: () => dispatch({ type: getMenuData.LOAD_WEBSITE_STATUS }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TopBar);
