import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { reduce } from 'lodash';
import styles from './style.module.scss';

const mapStateToProps = ({ menu }) => ({
    menuData: menu.menuData,
});

const Breadcrumbs = ({ location: { pathname }, menuData }) => {
    const [breadcrumbs, setBreadcrumbs] = useState([]);

    useEffect(() => {
        setBreadcrumbs(() => getBreadcrumbs());
    }, [pathname, menuData]);

    const getPath = (data, url, parents = []) => {
        const items = reduce(
            data,
            (result, entry) => {
                if (result.length) {
                    return result;
                }
                if (entry.url === url) {
                    return [entry].concat(parents);
                }
                if (entry.children) {
                    const nested = getPath(entry.children, url, [entry].concat(parents));
                    return (result || []).concat(nested.filter(e => !!e));
                }
                return result;
            },
            [],
        );
        return items;
    };

    const getBreadcrumbs = () => {
        const [activeMenuItem, ...path] = getPath(menuData, pathname);

        if (!activeMenuItem) {
            return [];
        }

        const breadcrumbs = [];

        const homeMenuItem = menuData[0];
        const isHomeMenuItemActive = activeMenuItem.key === homeMenuItem.key;
        const isDescendantOfHomeMenuItem = isDescendant(activeMenuItem, homeMenuItem);

        if (!isHomeMenuItemActive || !isDescendantOfHomeMenuItem || activeMenuItem.children) {
            if (activeMenuItem.title !== 'Performance') {
                breadcrumbs.push(
                    <Link key="home" to="/">
                        Home
                    </Link>,
                );
            }
        } else {
            breadcrumbs.push(
                ...path.reverse().map((item, index) => (
                    <React.Fragment key={item.key}>
                        <span className={styles.arrow} />
                        <Link to={item.url}>{item.title}</Link>
                    </React.Fragment>
                )),
            );
        }

        breadcrumbs.push(
            <React.Fragment key={activeMenuItem.key}>
                {activeMenuItem.title !== 'Performance' && <span className={styles.arrow} />}
                <strong>{activeMenuItem.title}</strong>
            </React.Fragment>,
        );

        return breadcrumbs;
    };

    const isDescendant = (menuItem, parentMenuItem) => {
        if (menuItem.key === parentMenuItem.key) {
            return true;
        }
        if (parentMenuItem.children) {
            return parentMenuItem.children.some(child => isDescendant(menuItem, child));
        }
        return false;
    };

    return (
        breadcrumbs.length > 0 && (
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">{breadcrumbs}</ol>
            </nav>
        )
    );
};

export default withRouter(connect(mapStateToProps)(Breadcrumbs));
